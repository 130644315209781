import {useEffect, useState} from "react";

import './App.css';
import {Button, ConfigProvider, Divider, message, QRCode} from "antd";

import Icon from "./assets/images/app_icon.svg";
import WechatOverlay from "./assets/images/wechat_overlay.svg";
import {AndroidFilled, AppleFilled} from "@ant-design/icons";


function App() {
    // search param function
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });


    const [messageApi, contextHolder] = message.useMessage();

    const [deepLink, setDeepLink] = useState("");
    const [storeLink, setStoreLink] = useState("");

    const [displayWechatCover, setDisplayWechatCover] = useState(false);

    const [isDesktop, setIsDesktop] = useState(false);

    useEffect( () => {
        async function load() {
            if (params.type == null || params.id == null) {
                messageApi.error('Type 和 Id 参数不得为空');

            } else {
                var ua = await checkUserAgent();

                await getDeepLink(params.type, params.id, ua);
                await getStoreLink(ua);
            }
        }

        load();

    }, [])


    useEffect( () => {
        if(deepLink !== ""){
            document.getElementById('openApp').click()
        }
    }, [deepLink])


    async function checkUserAgent() {
        const ua = navigator.userAgent.toLowerCase();
        var isWeixin = ua.indexOf("micromessenger") != -1;

        if (isWeixin) {
            return "wechat";

        } else if (/android/i.test(ua)) {
            return "android";

        } else if (/ipad|iphone|ipod/.test(ua)) {
            return "ios";
        }
        return "other";
    }


    async function getDeepLink(type, id, ua) {
        // provides ios and android deep link
        switch (type){
            case "product":
                if(ua==="ios" || ua==="android"){
                    setDeepLink("shopmy://product:8888/detail?id=" + id)
                    return "shopmy://product:8888/detail?id=" + id;
                }
                break;

            case "store":
                if(ua==="ios" || ua==="android"){
                    setDeepLink("shopmy://shop:8888/detail?id=" + id)
                    return "shopmy://shop:8888/detail?id=" + id;
                }
                break;

            default:
                messageApi.error('Type 参数不正确');
                return null;
                break;
        }

        // provides wechat reaction
        if (ua==="wechat"){
            setDisplayWechatCover(true);
            return null;
        }

        // provides pc reaction
        if (ua==="other"){
            setIsDesktop(true);
            return null;
        }
    }

    async function getStoreLink(ua) {

        if(ua==="ios"){
            setStoreLink("https://apps.apple.com/app/id1524238179");

        } else if(ua==="android"){
            setStoreLink("https://play.google.com/store/apps/details?id=com.xmobile.shopmy");

        }

    }




    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#f46e12',
                },
            }}
        >
            {contextHolder}
            {
                displayWechatCover === true &&
                <div className={"App overlay"}>
                    <img className={"icon"} src={"//app-apsoutheast2-static.shopmy.com.au/share/wechat_overlay.svg"} width={"280px"}></img>
                </div>
            }


            <div className="App main">
                <img className={"icon"} src={"//app-apsoutheast2-static.shopmy.com.au/share/app_icon1.svg"}></img>
                <h3 style={{color: "#F46E12", textAlign: "center"}}>享买购物 SHOPMY</h3><br/>
                {
                    isDesktop === false
                        ?
                        <>
                            <a href={storeLink}>
                                <Button type={"primary"} size={"large"} className={"button"} >下载 APP</Button>
                            </a>

                            <a href={deepLink} id={"openApp"}>
                                <Button type={"primary"} size={"large"} className={"button"}>开启链接</Button>
                            </a>
                        </>

                        :
                        <div className={"desktop"}>
                            <div className={"container"}>
                                <span className={"sub-title"}>使用手机查看此页面</span>
                                <br/>
                                <QRCode
                                    errorLevel="H"
                                    value={window.location.href}
                                />
                            </div>
                            <Divider type="vertical" style={{height: "265px"}}/>
                            <div className={"container"} style={{margin: "20px 35px 20px 82px"}}>
                                <span className={"sub-title"}>或前往商店查看 APP</span>
                                <br/>
                                <a href="https://apps.apple.com/app/id1524238179">
                                    <Button type={"primary"} size={"large"} className={"button"} style={{width: "168px"}}><AppleFilled />&nbsp;iOS APP</Button>
                                </a>

                                <a href="https://play.google.com/store/apps/details?id=com.xmobile.shopmy">
                                    <Button type={"primary"} size={"large"} className={"button"} style={{width: "168px"}}><AndroidFilled />&nbsp;Android APP</Button>
                                </a>
                            </div>


                        </div>

                }
            </div>
        </ConfigProvider>
    );
}

export default App;
